require("./bootstrap");
require("./gallery");
require("./form");
require("./jquery.mycart");

// import "jquery-ui/ui/widgets/datepicker.js";


// Bootstap form validation
(function () {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    const forms = document.querySelectorAll(".needs-validation");

    // Loop over them and prevent submission
    forms.forEach((form) => {
        form.addEventListener("submit", (event) => {
            if (!form.checkValidity()) {
                event.preventDefault();
                event.stopPropagation();
            }
            form.classList.add("was-validated");
        });
    });
})();
