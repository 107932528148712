$(function () {
    JSON.parse(localStorage["__mycart"] || '[]')
        .forEach((value) => {
            $('.img-blk').find(`button[data-id="${value.id}"]`).parent().addClass('active')
            $('.img-blk').find(`button[data-id="${value.id}"]`).text('Added')
        });
    
    $('.my-cart-btn').myCart({
        classCartIcon: 'my-cart-icon',
        classCartBadge: 'my-cart-badge',
        affixCartIcon: true,
    });

    $('.my-cart-checkout').on('click', function() {
        $('.modal-body').children().hide().end().append($('#book-free-quote')).end();
        $('.form-wrapper').not(':has(*):not(:empty)').append($('#book-free-quote').clone());
        $('.form-wrapper').find('input[type="radio"]').removeAttr('id')
        $('.modal-footer').hide();
        $('#exampleModalLabel').text('Get Quote!!');
        $('#book-free-quote').show()
    });
    
    $('.my-cart-icon').on('click', function() {
        $('.modal-body').children().hide()
        $('#exampleModalLabel').text('My Cart');
        $('.modal-body').find('#book-free-quote').hide()
        $('#my-cart-table').show()
        $('.modal-footer').show()
    });

    $('.form').on('click', function (event) {
        event.preventDefault();
    
        const $this = $(this);
        const $modal = $('.modal');
        const $modalBody = $('.modal-body');
        const $modalFooter = $('.modal-footer');
        const src = $this.find('img').attr('src');
        const button = $this.parent().find('.my-cart-btn');
        const nextText = $this.parent().next().text();
        
        $('.modal-body').children().hide()
    
        $modal.modal('show');
        $('#exampleModalLabel').text(nextText);
    
        const $img = $modalBody.find('.header-img');
        if ($img.length) {
            $img.attr('src', src).show();
        } else {
            $modalBody.append(`<img src="${src}" class="img-fluid header-img">`);
        }
    
        $modalFooter.append(button).show();
        $this.parent().append(button.clone());
    });
    

    $('.modal').on('hide.bs.modal', function (e) {
        const button = $(this).find('.my-cart-btn')
        const form = $('.form-wrapper').find('#book-free-quote')

        if(button.length) $('.img-blk').find(`button[data-id="${button.data('id')}"]`).replaceWith(button)

        form.length && $('.modal-body').find('#book-free-quote').length
            ? form.replaceWith($('.modal-body').find('#book-free-quote')) 
            : $('.form-wrapper').append($('.modal-body').find('#book-free-quote'))
    })
});